import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import OverlaySplash from "../happenings/OverlaySplash"

const BackgroundStyled = styled.div`
  position: relative;
  height: 420px;
  margin-top: -138px;
  text-align: center;
  @media (min-width: 768px) {
    height: 580px;
    margin-top: -97px;
  }
`

const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-size: cover;
`

const HomeHero = ({ hero }) => {
  return (
    <BackgroundStyled>
      <BackgroundImageStyled fluid={hero.fluid} critical={true} durationFadeIn={100}>
        <OverlaySplash />
      </BackgroundImageStyled>
    </BackgroundStyled>
  )
}

export default HomeHero
