import React, { useRef } from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import BlueLineImg from "../../resources/images/blue-line.svg"
import TwitterImg from "../../resources/images/happenings/twitter-icon.svg"
import FacebookImg from "../../resources/images/happenings/facebook-icon.svg"
import MailImg from "../../resources/images/happenings/mail-icon.svg"
import ShareImg from "../../resources/images/happenings/share-icon.svg"
import colors from "../../styles/colors"

const StyledRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;   
    padding-left: 33px;
    padding-right: 33px; 
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

const SocialMedias = styled.div`
    text-align: center;
`;


const BlueLine = styled.img`
    width: 80px;
`;

const SocialButtons = styled.div`
    margin-bottom: 110px;
    margin-top: 52px;
    @media(min-width: 992px){
        margin-bottom: 180px;
    }
`;

const Icon = styled.img`
    width: 20px;
    height: 16px;
    margin: 0 18px;
`;

const IconButton=styled.button`
    background: transparent;
    cursor: pointer;
    border: none;
    input {
      display: none;
      overflow: hidden;
    }
    
    &:focus {
        outline: none;
        box-shadow: none;;
        background: transparent;
    }
`;

const BorderStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 38.4px;
  width: 38.4px;
  transform: scale(0.8333333) rotate(45deg);
  border: 1.5px solid transparent;
  transition: .3s;
  img {
    transform: scale(1.2) rotate(-45deg);
  }
  &:hover {
    border: 1.5px solid ${colors.mint};
    transform: scale(1) rotate(45deg);
    transition: .5s;
    img {
        transform: scale(1.2) rotate(-45deg);
        transition: .3s;
    }
  }
`;

const SocialMedia = () => {
  let url;
  if (typeof window !== "undefined") {
    url = window.location.href;
  }
  return (
    <StyledRow>
      <ColStyled>
        <SocialMedias>
          <BlueLine src={BlueLineImg} />
          <SocialButtons>
            <IconButton>
              <BorderStyled>
                <a href={"https://twitter.com/lostatsea_co"} target="_blank">
                  <Icon src={TwitterImg} alt={"Twitter"}/>
                </a>
              </BorderStyled>
            </IconButton>
            <IconButton>
              <BorderStyled>
                <a href={"https://www.facebook.com/lostatseaco"} target="_blank">
                  <Icon src={FacebookImg} alt={"Facebook"}/>
                </a>
              </BorderStyled>
            </IconButton>
            <IconButton>
              <BorderStyled>
                <a href={"mailto:ahoy@lostatsea.co.uk"} target="_blank">
                  <Icon src={MailImg} alt={"Mail"}/>
                </a>
              </BorderStyled>
            </IconButton>
            <IconButton onClick={() => {navigator.clipboard.writeText(url)}}>
                <BorderStyled>
                  <Icon src={ShareImg} alt={"Share"}/>
                </BorderStyled>
            </IconButton>
          </SocialButtons>
        </SocialMedias>
      </ColStyled>
    </StyledRow>
  )
}

export default SocialMedia;