import React, { useState, useRef } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import Img from "gatsby-image"
import RightArrow from "../../resources/images/happenings/black-arrow-right.svg"
import LeftArrow from "../../resources/images/happenings/black-arrow-left.svg"

const SliderContainer = styled.div`
  width: 100%;
  position: relative;
`

const Slide = styled.div`
  outline: none;
  border: 0;
  margin: auto;
`

const StyledSlider = styled(Slider)`
  .slick-track,
  .slick-list,
  .slick-slide {
    height: 100%;
    width: auto;
  }
`

const CarouselController = styled.img`
  cursor: pointer;
  width: 40px;
  height: 6px;
  border: none;
`

const Previous = styled.span`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 57%;
  @media (min-width: 992px) {
    bottom: 55%;
    right: -3%;
    transform: rotate(270deg);
    margin-top: 0;
  }
  @media (min-width: 1400px) {
    right: -9%;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding-top: 20px;
  }
`

const Next = styled.span`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 35%;
  @media (min-width: 992px) {
    bottom: 46%;
    right: -3.8%;
    transform: rotate(90deg);
  }
  @media (min-width: 1400px) {
    right: -9.8%;
  }
  @media (min-width: 768px) and (max-width: 992px) {
    padding-top: 20px;
  }
`

const SlideImg = styled(Img)`
  height: 366px;
  width: auto;
  margin-top: 57px;
  margin-bottom: 45px;
  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
  }
`

const DesktopOnly = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`

const MobileOnly = styled.div`
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
`

const Carousel = props => {
  const [slideIndex, setSlideIndex] = useState(0)
  const slideRef = useRef()

  const changeSlide = diff => {
    const index = slideIndex + diff

    slideRef.current.slickGoTo(index)
  }
  const { slides } = props

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: "992px",
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <SliderContainer>
      <StyledSlider ref={slideRef} {...settings}>
        {slides.map((slide, index) => {
          return (
            <Slide key={"image-" + index}>
              <SlideImg fluid={slide.image.fluid} />
            </Slide>
          )
        })}
      </StyledSlider>
      <DesktopOnly>
        <Previous onClick={() => changeSlide(-1)}>
          <CarouselController src={RightArrow} />
        </Previous>
        <Next onClick={() => changeSlide(1)}>
          <CarouselController src={RightArrow} />
        </Next>
      </DesktopOnly>
      <MobileOnly>
        <Previous onClick={() => changeSlide(-1)}>
          <CarouselController src={LeftArrow} />
        </Previous>
        <Next onClick={() => changeSlide(1)}>
          <CarouselController src={RightArrow} />
        </Next>
      </MobileOnly>
    </SliderContainer>
  )
}

export default Carousel
