import React, { useState, useRef } from "react"
import styled from "styled-components"
import { Container, Row } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import BlueLineImg from "../../resources/images/blue-line.svg"
import BlockBackgroundImg from "../../resources/images/happenings/background-block.jpg"
import BlockBackgroundImgMobile from "../../resources/images/happenings/background-mobile.jpg"
import LeftArrow from "../../resources/images/happenings/white-arrow-left.svg"
import RightArrow from "../../resources/images/happenings/white-arrow-right.svg"
import Slider from "react-slick"
import Img from "gatsby-image"
import { Link } from "gatsby"

const FavouritesBlackBackground = styled.div`
  width: 100%;
  background: black;
`

const FavouritesContainer = styled(Container)`
  padding: 91px 0 120px 0;
  @media (min-width: 992px) {
    padding: 172px 0 170px 0;
  }
`

const StyledRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  padding-left: 33px;
  padding-right: 33px;
  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const FavouritesContent = styled.div`
  margin: auto;
  text-align: center;
`

const BlueLine = styled.img`
  width: 80px;
`

const Title = styled.div`
  color: white;
  padding-top: 34px;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-bottom: 80px;
  ${fonts.swissBlackExtended};
  @media (min-width: 768px) {
    padding-top: 40px;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 2.5px;
  }
`

const FavouritesBackground = styled.div`
  position: relative;
  background: url(${BlockBackgroundImgMobile}) no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  @media (min-width: 678px) {
    width: 100vw;
    background: url(${BlockBackgroundImg}) no-repeat;
    background-size: cover;
  }
`

const SliderContainer = styled.div`
  width: 100%;
`

const Slide = styled.div`
  outline: none;
  border: 0;
  margin: auto;
`

const StyledSlider = styled(Slider)`
  .slick-track,
  .slick-list,
  .slick-slide {
    height: 100%;
    width: auto;
  }
`

const SlideImg = styled(Img)`
  height: auto;
  width: auto;
  max-width: 363px;
  margin: 0 8.5px;
  max-height: 215px;
  @media (min-width: 1440px) {
    margin: auto;
  }
  @media (max-width: 992px) {
    margin: auto;
    max-width: 347px;
  }
`

const SlideDate = styled.div`
  font-size: 12px;
  line-height: 14px;
  ${fonts.gilroyBold};
  letter-spacing: 2.8px;
  color: #9ca8b5;
  text-transform: uppercase;
  padding-top: 33px;
  padding-bottom: 16px;
  max-width: 347px;
  margin: auto;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 8.5px;
  }
  @media (min-width: 992px) {
    max-width: 363px;
  }
`

const SlideTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  ${fonts.swissBlackExtended};
  letter-spacing: 1px;
  max-width: 347px;
  margin: auto;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 8.5px;
  }
  @media (min-width: 992px) {
    max-width: 363px;
  }
`

const CarouselController = styled.img`
  cursor: pointer;
  width: 40px;
  height: 6px;
  border: none;
`

const Previous = styled.span`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  margin-top: 60px;
  right: 55%;
  @media (min-width: 992px) {
    display: none;
  }
`

const Next = styled.span`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  margin-top: 60px;
  left: 55%;
  @media (min-width: 992px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const SimilarStories = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const sliderRef = useRef()

  const changeSlide = diff => {
    const index = slideIndex + diff

    sliderRef.current.slickGoTo(index)
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: false,
    swipe: true,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
    slidesToShow: slides.length >= 3 ? 3 : slides.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: "992px",
        settings: {
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <FavouritesBlackBackground>
      <FavouritesBackground>
        <FavouritesContainer>
          <StyledRow>
            <FavouritesContent>
              <BlueLine src={BlueLineImg} />
              <Title>Similar Stories</Title>
            </FavouritesContent>
            <SliderContainer>
              <StyledSlider ref={sliderRef} {...settings}>
                {slides.map((slide, index) => {
                  return (
                    <Slide key={"image-" + index}>
                      <StyledLink to={`/happenings/${slide.slug}`}>
                        <SlideImg fluid={slide.thumbnail.fluid} />
                      </StyledLink>
                      <SlideDate>{slide.date}</SlideDate>
                      <StyledLink to={`/happenings/${slide.slug}`}>
                        <SlideTitle>{slide.title}</SlideTitle>
                      </StyledLink>
                    </Slide>
                  )
                })}
              </StyledSlider>
              <Previous onClick={() => changeSlide(-1)}>
                <CarouselController src={LeftArrow} />
              </Previous>
              <Next onClick={() => changeSlide(1)}>
                <CarouselController src={RightArrow} />
              </Next>
            </SliderContainer>
          </StyledRow>
        </FavouritesContainer>
      </FavouritesBackground>
    </FavouritesBlackBackground>
  )
}

export default SimilarStories
