import React from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import BlueLineImg from "../../resources/images/blue-line.svg"
import fonts from "../../styles/fonts"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const StyledRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;   
    padding-left: 33px;
    padding-right: 33px; 
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`;

const PostHeader = styled.div`
    text-align: center;
    margin: auto;
`;

const PostDate = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #222E3C;
    letter-spacing: 2.8px;
    ${fonts.gilroyBold};
    text-transform: uppercase;
`;

const PostTitle = styled.div`
    font-size: 20px;
    line-height: 24px;
    color: #222E3C;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    padding-top: 25px;
    padding-bottom: 32px;
    @media(min-width: 768px){
        padding-top: 20px;
        padding-bottom: 40px;
        font-size: 42px;
        line-height: 50px;
        letter-spacing: 2.5px;
        max-width: 850px;
    }
`;

const IntroText = styled.div`
    width: auto;
    text-align: center;
    margin-top: 35px;
    max-width: 554px;
    margin-left: auto;
    margin-right: auto;
    @media(min-width: 767px){
        margin-bottom: 44px;
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

const BlueLine = styled.img`
    width: 80px;
`;

const Text = styled.div`
    font-size: 15px;
    ${fonts.gilroyRegular};
    color: #222E3C;
    letter-spacing: 0;
    line-height: 21px;
    padding-bottom: 25px;
    a {
      color: #222E3C;
    }
`;

const Intro = ({title, date, intro}) => {
  return (
    <StyledRow>
      <PostHeader>
        <PostDate>{date}</PostDate>
        <PostTitle>{title}</PostTitle>
        <BlueLine src={BlueLineImg} />
      </PostHeader>
      <ColStyled>
        <IntroText>
          <Text>{documentToReactComponents(intro.json)}</Text>
        </IntroText>
      </ColStyled>
    </StyledRow>
  )
}

export default Intro
