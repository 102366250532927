import React from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import fonts from "../../styles/fonts"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import colors from "../../styles/colors"

const StyledRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;   
    padding-left: 33px;
    padding-right: 33px; 
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`;

const FirstPostImage = styled(Img)`
    max-width: 348px;
    height: auto;
    margin: 15px auto 51px auto;
    @media(min-width: 768px){
       max-width: 553px;
       margin-top: 15px;
    }
    @media(min-width: 992px){
       margin-bottom: 117px;
       margin-top: 56px;
    }
`;

const TextContainer = styled.div`
    margin-top: 0;
    @media(min-width: 992px){
        margin-top: 109px;
        padding-right: 112px;
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

const TextBox = styled.div`
    font-size: 15px;
    ${fonts.gilroyRegular};
    color: #222E3C;
    letter-spacing: 0;
    line-height: 21px;
    p {
      padding-bottom: 25px;
      :last-child {
        padding-bottom: 25px;
      }
    }
    
    a {
      color: ${colors.navy};
      transition: all 0.2s;
      &:hover,
      &:active,
      &:focus {
          color: ${colors.mint};
      }
    }
`;

const BlockTextImage = ({image, content}) => {
  return (
    <StyledRow>
      <ColStyled lg={6} smOrder={1} lgOrder={2}>
        <FirstPostImage
          fluid={image.fluid}
          alt={image.title}
        />
      </ColStyled>
      <ColStyled lg={6} smOrder={2} lgOrder={1}>
        <TextContainer>
          <TextBox>
            {documentToReactComponents(content.json)}
          </TextBox>
        </TextContainer>
      </ColStyled>
    </StyledRow>
  )
}

export default BlockTextImage
