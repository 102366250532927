import React from "react"
import styled from "styled-components"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import Hero from "../components/article/Hero"
import Intro from "../components/article/Intro"
import BlockText from "../components/article/BlockText"
import BlockTextImage from "../components/article/BlockTextImage"
import Carousel from "../components/article/Carousel"
import SocialMedia from "../components/article/SocialMedia"
import { Container } from "styled-bootstrap-grid"
import SimilarStories from "../components/article/SimilarStories"

const PostContainer = styled(Container)`
    padding: 60px 0 0 0;
    @media(min-width: 768px){
        padding-top: 103px;
        padding-left: 0;
        padding-right: 0;
    }
`;

const article = (props) => {
  const path = props.location.pathName
  const articleData = props.data.article;
  const { metaTitle, metaDescription, title, date, intro, hero, contents, similarStories } = articleData
  const pageContent = []
  if (contents) {
    contents.forEach((block) => {
      if (block.__typename === "ContentfulBlockText") {
        pageContent.push(<BlockText {...block} />)
      }
      if (block.__typename === "ContentfulBlockTextImage") {
        pageContent.push(<BlockTextImage {...block} />)
      }
      if (block.__typename === "ContentfulBlockCarousel") {
        pageContent.push(<Carousel {...block} />)
      }
    })
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        path={path}
      />
      {hero && <Hero hero={hero}/>}
      <PostContainer>
        <Intro title={title} date={date} intro={intro} />
          {pageContent}
          <SocialMedia />
      </PostContainer>
      {similarStories && <SimilarStories slides={similarStories}/>}
    </Layout>
  )
}

export default article

export const pageQuery = graphql`
  query articleQuery($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      title
      date(formatString: "MMM DD, YYYY")
      intro {
        json
      }
      hero {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contents {
        ... on ContentfulBlockText {
          title
          content {
            json
          }
        }
        ... on ContentfulBlockTextImage {
          content {
            json
          }
          image {
            fluid(maxWidth: 1100, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        ... on ContentfulBlockCarousel {
          slides {
            ... on ContentfulBlockImage {
              image {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      similarStories: relatedArticles {
        title
        date(formatString: "MMM DD, YYYY")
        intro {
          json
        }
        slug
        thumbnail {
          fluid(maxWidth: 640, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
