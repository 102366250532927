import React from 'react'
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const StyledRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;   
    padding-left: 33px;
    padding-right: 33px; 
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

const Subtitle = styled.div`
    text-transform: uppercase;
    line-height: 19px;
    letter-spacing: 1px;
    font-size: 16px;
    color: #222E3C;
    ${fonts.swissBlackExtended};
    text-align: center;
    margin: 64px auto 30px auto;
    @media(min-width: 992px){
        margin-top: 124px;
    }
`;

const TextBlock = styled.div`
    font-size: 15px;
    ${fonts.gilroyRegular};
    color: #222E3C;
    letter-spacing: 0;
    line-height: 21px;
    max-width: 744px;
    margin: auto;
    padding-bottom: 62px;
    p {
      padding-bottom: 25px;
      &:last-child {
        padding-bottom: 0;
      }
    }

    a {
      color: inherit;
    }
    @media(min-width: 767px){
        padding-bottom: 100px;
    }
`

const BlockText = ({title, content}) => {
  return (
    <StyledRow>
    <ColStyled md={12}>
      {title && <Subtitle>{title}</Subtitle>}
      <TextBlock>
        {documentToReactComponents(content.json)}
      </TextBlock>
    </ColStyled>
  </StyledRow>
  )
}

export default BlockText
